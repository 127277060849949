export const actionTypes = {
  getProducts: 'GET_PRODUCTS',
  getTags: 'GET_TAGS',
  getProduct: 'GET_PRODUCT',
  updateProductsQuantity: 'UPDATE_PRODUCT_QUANTITY',
  getAllProduct: 'GET_ALL_PRODUCT',
  createProduct: 'CREATE_PRODUCTS',
  createTag: 'CREATE_TAG',
  addCustomerDetails: 'ADD_CUSTOMER_DETAILS',
  editProduct: 'EDIT_PRODUCTS',
  createSize: 'CREATE_SIZE',
  createCategory: 'CREATE_CATEGORY',
  updateCategory: 'UPDATE_CATEGORY',
  deleteCategory: 'DELETE_CATEGORY',
  createStore: 'CREATE_STORE',
  updateStore: 'UPDATE_STORE',
  deleteStore: 'DELETE_STORE',
  createCustomer: 'CREATE_CUSTOMER',
  getCategories: 'GET_CATEGORIES',
  getStores: 'GET_STORES',
  getCustomers: 'GET_CUSTOMERS',
  getSizes: 'GET_SIZE',
  createColor: 'CREATE_COLOR',
  getColors: 'GET_COLOR',
  login: 'LOGIN_REQUEST',
  logout: 'LOGOUT_REQUEST',
  getOrders: 'GET_ORDERS',
  getOrder: 'GET_ORDER',
  confirmOrder: 'CONFIRM_ORDER',
  updateOrder: 'UPDATE_ORDER',
  updatePrice: 'UPDATE_PRICE',
  createOrder: 'CREATE_ORDER',
  deleteOrderItem: 'DELETE_ORDER_ITEM',
  deleteAllOrderItems: 'DELETE__ALL_ORDER_ITEM',
  filterProducts: 'FILTER_PRODUCTS',
  filterStats: 'FILTER_STATS',
  deleteCustomer: 'DELETE_CUSTOMER',
  updateCustomer: 'UPDATE_CUSTOMER',
  currentCustomer: 'CURRENT_CUSTOMER',
  createUser: 'CREATE_USER',
  createVendor: 'CREATE_VENDOR',
  createExpense: 'CREATE_EXPENSE',
  getUsers: 'GET_USERS',
  getVendors: 'GET_VENDORS',
  getExpenses: 'GET_EXPENSES',
  deleteUser: 'DELETE_USER',
  updateUser: 'UPDATE_USER',
  deleteVendor: 'DELETE_VENDOR',
  updateVendor: 'UPDATE_VENDOR',
  deleteExpense: 'DELETE_EXPENSE',
  updateExpense: 'UPDATE_EXPENSE',
  getOnHold: 'ON_HOLD_ORDERS',
  editOnHold: 'EDIT_ON_HOLD',
  getProductsStats: 'GET_PRODUCTS_STATS',
  updateOrderStatus: 'UPDATE_ORDER_STATUS',
  startLoading: 'START_LOADING',
  stopLoading: 'STOP_LOADING',
  deleteCurrentOrderItem: 'DELETE_CURRENT_ORDER_ITEM',
  setOnline: 'SET_ONLINE_STATUS',
  setOffline: 'SET_OFFLINE_STATUS',
};
